import { useState, useEffect, useContext } from 'react'

// COMPONENTS
import AppBar from 'components/AppBar/AppBar'
import DataGridFilters from 'components/DataGridFilters/DataGridFilters'
import DataGridTable from 'components/DataGridTable/DataGridTable'
import LoadingPaper from 'components/LoadingPaper/LoadingPaper'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import { Menu, MenuItem } from '@mui/material'

// MUI ICONS
import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import IconRequest from '@mui/icons-material/Info'
import IconPeople from '@mui/icons-material/People'

// RAMDA
import { isEmpty, reject } from 'ramda'

// SERVICES
import { 
  postApplicantList,
  putUpdateAccountStatus,
  putUpdateApprovalStatus, 
} from 'services/applicants'
import { getImpersonateUser } from 'services/impersonate'

// STYLES
import useLayoutStyles from 'styles/layoutPrivate'
import useStyles from './applicantsUseStyles'

// UTILITIES
import { convertDate } from 'utilities/date'
import { 
  capitalizeEachWord, 
  getDefaultErrorMessage,
} from 'utilities/string'
import { doesSuccessfullyCallTheApi } from 'utilities/validation'

const Applicants = () => {
  const layoutClasses = useLayoutStyles()
  const classes = useStyles()  
  const { 
    setSnackbarObject, 
    auth,
  } = useContext(AllPagesContext)

  const [anchorEl, setAnchorEl] = useState(null)
  const [rowID, setRowID] = useState(null)

  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }

  // TO DO: CHANGE EVERY MINWIDTH FIELD VALUE
  const initialColumns = [
    {
      field: 'organizationName',
      headerName: 'Company',
      flex: 1,
      minWidth: 300,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => (
        <Stack>
          {/* COMPANY NAME */}
          <Typography
            variant='inherit'
          >
            {params.row.organizationName}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'organizationCode',
      headerName: 'Org Code',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => (
        <Stack>
          {/* ORGANIZATION CODE */}
          <Typography
            variant='inherit'
          >
            #{params.row.organizationCode}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'phoneNumber',
      headerName: 'Contact',
      flex: 1,
      minWidth: 150,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => (
        <Stack>
          {/* PHONE NUMBER */}
          <Typography
            variant='inherit'
          >
            {params.row.phoneNumber}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'companyEmail',
      headerName: 'Email',
      flex: 1,
      minWidth: 250,
      hide: false,
      areFilterAndSortShown: true,
      renderCell: (params) => (
        <Stack>
          {/* EMAIL */}
          <Typography
            variant='inherit'
          >
            {params.row.companyEmail}
          </Typography>
        </Stack>
      ),
    },
    {
      field: 'accountValidity',
      headerName: 'Valid',
      headerAlign: 'center',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: false,
    },
    {
      field: 'fileAttachment',
      headerName: 'File',
      headerAlign: 'center',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: false,
    },
    {
      field: 'inventory',
      headerName: 'Inventory',
      headerAlign: 'center',
      flex: 1,
      minWidth: 120,
      hide: false,
      areFilterAndSortShown: false,
    },
    {
      field: 'logInAsUser',
      headerName: 'Impersonate',
      headerAlign: 'center',
      width: 120,
      hide: false,
      areFilterAndSortShown: false,
      renderCell: (params) => {
        return(
          <Stack 
            alignItems='center' 
            width='100%'
          >
            <IconButton
              size='small'
              onClick={() => handleLoginIconButtonClick(params)}
            >
              <IconPeople fontSize='small'/>
            </IconButton>
          </Stack>
        )
      }
    },
    {
      field: 'accountStatus',
      headerName: 'NLE Status',
      headerAlign: 'center',
      width: 100,
      hide: false,
      areFilterAndSortShown: false,
      renderCell: (params) => (
        <Stack 
          alignItems='center' 
          width='100%'
        >
          <Switch
            size='small'
            checked={params.value === 'ACTIVE'}
            color='secondary'
            onChange={(event) => handleStatusSwitchChange(event, params)}
          />
        </Stack>
      ),
    },
    {
      field: 'approvalStatus',
      headerName: 'Actions',
      headerAlign: 'center',
      width: 220,
      hide: false,
      areFilterAndSortShown: false,
      renderCell: (params) => {
        if (params.value === 'ACCEPTED' || params.value === 'REJECTED') return (
          <Stack
            alignItems='center' 
            width='100%'
          >
            <Button
              startIcon={
                params.value === 'ACCEPTED' 
                  ? <CheckCircleIcon/> 
                  : <CancelIcon/>
              }
              disableElevation
              disabled
              className={
                params.value === 'ACCEPTED' 
                  ? classes.columnApprovalStatusAccepted 
                  : classes.columnApprovalStatusRejected
              }
              size='small'
              variant='outlined'
              sx={{ margin: 1 }}
            >
              {params.value === 'ACCEPTED' ? 'Approved' : 'Rejected'}
            </Button>
          </Stack>
        )
        else if (params.value === 'REQUEST') return (
          <Stack
            alignItems='center' 
            width='100%'
            margin='5px'
            className='no-zoom'
          >
            <Button
              startIcon={<IconRequest/>}
              className={classes.columnApprovalStatusRequest}
              disableElevation
              size='small'
              variant='outlined'
              onClick={(e) => {
                setRowID(params.row.id)
                setAnchorEl(e.currentTarget)
              }}
            >
              Open
            </Button>
          </Stack>
        )
      }
    }
  ]

  const groupByList = [
    {
      title: 'Don\'t Group',
      value: null,
    },
  ]

  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)
  const [ isFilterOn, setIsFilterOn ] = useState(false)
  const [ selectedGroupBy, setSelectedGroupBy ] = useState(groupByList[0])
  const [ total, setTotal ] = useState(0)
  const [ pageNumber, setPageNumber ] = useState(0)
  const [ pageSize, setPageSize ] = useState(100)
  const [ order, setOrder ] = useState('desc')
  const [ orderBy, setOrderBy ] = useState('')
  const [ filters, setFilters ] = useState({})
  const [ selectionModel, setSelectionModel ] = useState([])
  const [ isDateRangeTimePickerOpen, setIsDateRangeTimePickerOpen ] = useState(false)
  const [ dateRangeTimeValue, setDateRangeTimeValue ] = useState(['',''])
  const [ tableData, setTableData ] = useState([])
  const [ isDataGridLoading, setIsDataGridLoading ] = useState(false)
  const [ mustReloadDataGrid, setMustReloadDataGrid ] = useState(true)
  const [ search, setSearch ] = useState('')

  // TO DO: CHANGE THIS
  const groupingColDef = {
    headerName: 'Date & Time',
    renderCell: (params) => {
      if (!params.rowNode.isAutoGenerated) return params.row.dateAndTime

      return (
        <Typography 
          variant='subtitle1' 
          className={layoutClasses.groupingRow}
          noWrap
        >
          {capitalizeEachWord(params.rowNode.groupingKey.replace('_', ' '))}
        </Typography>
      )
    },
  }

  const handleStatusSwitchChange = async (inputEvent, inputParams) => {
    const abortController = new AbortController()

    const resultUpdateAccountStatus = await putUpdateAccountStatus(
      abortController.signal, 
      inputParams.row.id,
      inputEvent.target.checked ? 'ACTIVE' : 'INACTIVE',
      auth.accessToken,
    ) 
    
    if (doesSuccessfullyCallTheApi(resultUpdateAccountStatus.status)) {
      setSnackbarObject({
        open: true,
        severity:'success',
        title:'',
        message:'Successfully updating the user status',
      })

      setMustReloadDataGrid(true)
    }
    else setSnackbarObject({
      open: true,
      severity: 'error',
      title: 'Failed to update the user status',
      message: getDefaultErrorMessage(resultUpdateAccountStatus),
    })

    abortController.abort()
  }

  const handleActionButtonClick = async (inputCommand) => {
    const abortController = new AbortController()

    const resultUpdateApprovalStatus = await putUpdateApprovalStatus(
      abortController.signal,
      rowID,
      inputCommand,
      auth.accessToken,
    )

    if (doesSuccessfullyCallTheApi(resultUpdateApprovalStatus.status)) {
      setSnackbarObject({
        open: true,
        severity:'success',
        title:'',
        message:'Successfully updating the user status',
      })

      setMustReloadDataGrid(true)
    }
    else setSnackbarObject({
      open: true,
      severity: 'error',
      title: 'Failed to update the user status',
      message: getDefaultErrorMessage(resultUpdateApprovalStatus),
    })

    abortController.abort()
  }

  const handleColumnsMenuItemClick = (inputItem, inputIndex) => {
    let tempSelectedColumnList = [...selectedColumnList]
    if(
      // TO DO: CHANGE THESE FIELDS LATER
      (selectedGroupBy.value && inputItem.field === '') ||
      (selectedGroupBy.value && inputItem.field === '')
    ) {}
    else {
      tempSelectedColumnList[inputIndex].hide = !tempSelectedColumnList[inputIndex].hide
    }
    setSelectedColumnList(tempSelectedColumnList)
  }

  const handleSelectDateRangePickerButtonClick = (newValue) => {
    setDateRangeTimeValue(newValue)
    setIsDateRangeTimePickerOpen(false)
  }

  const handleLoginIconButtonClick = async (inputParams) => {
    const abortController = new AbortController()

    const resultImpersonateUser = await getImpersonateUser(
      abortController.signal,
      inputParams.row.companyEmail,
      auth.accessToken,
    )

    if(doesSuccessfullyCallTheApi(resultImpersonateUser.status)) {
      navigator.clipboard.writeText(`${process.env.REACT_APP_DEPLOYED_DEPO_APP_URL}/impersonate/token=${resultImpersonateUser?.data?.access_token}`)
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Success copying the ID to clipboard',
      })
    } else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '' ,
        message: resultImpersonateUser.data.error,
      })
    }
    
    abortController.abort()
  }

  const loadApplicantListData = async (inputIsMounted, inputAbortController) => {
    setIsDataGridLoading(true)

    const newFilters = reject(isEmpty, filters)

    const resultApplicantList = await postApplicantList(
      inputAbortController.signal, 
      {
        page: pageNumber,
        size: pageSize,
        sort: order && orderBy ? `${orderBy},${order}` : 'id,asc'
      },
      {
        globalSearch: search,
        ...newFilters,
      },
      auth.accessToken,
    )

    if (resultApplicantList.status === 200 && inputIsMounted) {
      setTableData(resultApplicantList.data.rows)
      setTotal(resultApplicantList.data.totalElements)
    }

    setIsDataGridLoading(false)
    setMustReloadDataGrid(false)
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()
  
    mustReloadDataGrid && loadApplicantListData(isMounted, abortController)
  
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [mustReloadDataGrid])

  useEffect(() => {
    setMustReloadDataGrid(true)
  }, [filters, search, pageSize, pageNumber, order, orderBy])

  return (
    <>
      {/* APPBAR */}
      <AppBar
        search={search}
        setSearch={setSearch}
        pageTitle='Applicant List'
        hasFab={false}
      />

      {/* APPROVAL */}
      <Stack>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className='no-zoom'
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{'& .MuiMenuItem-root' : {zoom : 0.85}}}
        >
          {/* ACCEPT */}
          <MenuItem onClick={() => handleActionButtonClick('ACCEPTED')}>
            <Stack
              direction='row'
              alignItems='center'
              spacing={1}
              sx={{color: '#0000008A'}}
            >
              <CheckCircleIcon/>
              <Typography variant='caption'>
                Approve
              </Typography>
            </Stack>
          </MenuItem>

          {/* REJECT */}
          <MenuItem  onClick={() => handleActionButtonClick('REJECTED')}>
            <Stack
              direction='row'
              alignItems='center'
              spacing={1}
              sx={{color: '#0000008A'}}
            >
              <CancelIcon/>
              <Typography variant='caption'>
                Reject
              </Typography>
            </Stack>
          </MenuItem>
        </Menu>
      </Stack>

      {/* CONTENT */}
      <LoadingPaper isLoading={isDataGridLoading}>
        <DataGridFilters
          // COLUMN
          columns={initialColumns}
          selectedColumnList={selectedColumnList}
          handleColumnsMenuItemClick={handleColumnsMenuItemClick}
          // FILTER
          isFilterOn={isFilterOn}
          setIsFilterOn={setIsFilterOn}
          // GROUP BY ROW
          groupByList={groupByList}
          selectedGroupBy={selectedGroupBy}
          setSelectedGroupBy={setSelectedGroupBy}
          // TOTAL
          totalRows={total}
          // DATE RANGE TIME
          dateRangeValue={dateRangeTimeValue}
          isDateRangeTimePickerOpen={isDateRangeTimePickerOpen} 
          setIsDateRangeTimePickerOpen={setIsDateRangeTimePickerOpen}
          handleSelectDateRangePickerButtonClick={handleSelectDateRangePickerButtonClick}
          handleCancelDateRangePickerButtonClick={() => setIsDateRangeTimePickerOpen(false)}
          // DOWNLOAD
          isDownloadEnabled={selectionModel.length > 0}
          handleDownloadButtonClick={() => console.log('download')}
        />

        <DataGridTable
          // BASE
          initialColumns={initialColumns}
          selectedColumnList={selectedColumnList}
          setSelectedColumnList={setSelectedColumnList}
          rows={tableData}
          // PAGINATION
          total={total}
          page={pageNumber}
          pageSize={pageSize}
          // ORDER
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          setPage={setPageNumber}
          setPageSize={setPageSize}
          // FILTER
          setFilters={setFilters}
          isFilterOn={isFilterOn}
          // GROUP BY ROW
          selectedGroupBy={selectedGroupBy}
          getTreeDataPath={(row) => [ row.organizationName, row.id ]} // TO DO: CHANGE THIS
          groupingColDef={groupingColDef}
          // SELECTION
          selectionModel={selectionModel} 
          setSelectionModel={setSelectionModel}
        />
      </LoadingPaper>
    </>
  )
}

export default Applicants