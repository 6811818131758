import { useEffect, useContext, useState } from 'react'

// CHART DATA
import { getPieChartOptions } from './PieChart/pieChartData'

// COMPONENTS
import PieChart from './PieChart/PieChart'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import { Stack, Typography } from '@mui/material'

// SERVICE
import { getTopLocation, getTopFleetManager } from 'services/home'

// STYLES
import useStyles from '../homeUseStyles'

const RightContent = () => {
  const classes = useStyles()

  const { auth } = useContext(AllPagesContext)

  const topLocationColor = ['#4CAF50', '#03A9F4', '#FF9800']
  const topFleetColor = ['#03A9F4', '#4CAF50', '#FF9800', '#C2185B', '#AA00FF']

  const [topLocation, setTopLocation] = useState([])
  const [topFleetManager, setTopFleetManager] = useState([])

  // LOAD TOP LOCATION DATA
  const loadTopLocation = async (inputIsMounted, inputAbortController) => {
    const resultTopLocation = await getTopLocation(inputAbortController.signal, auth.accessToken)
    
    if(resultTopLocation.status === 200 && inputIsMounted) {
      const sliceTopLocationData = resultTopLocation?.data.slice(0,3)

      setTopLocation(sliceTopLocationData.map((item, index) => {
        return {
          name: item.location,
          value: item.count,
          color: topLocationColor[index]
        }
      }))
    }
  }

  // LOAD TOP FLEET MANAGER DATA
  const loadTopFleetManager = async (inputIsMounted, inputAbortController) => {
    const resultTopManager = await getTopFleetManager(inputAbortController.signal, auth.accessToken)
    
    if(resultTopManager.status === 200 && inputIsMounted) {
      const sliceTopManagerData = resultTopManager?.data.slice(0,5)

      setTopFleetManager(sliceTopManagerData.map((item, index) => {
        return {
          name: item.fleetManager,
          value: item.count,
          color: topFleetColor[index]
        }
      }))
    }
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()
  
    loadTopLocation(isMounted, abortController)
    loadTopFleetManager(isMounted, abortController)
    
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  return (
    <Stack spacing={2.5}>
      {/* TOP FLEET MANAGER */}
      <Stack alignItems='center' className={classes.rightContentRoot}>
        {/* TITLE */}
        <Typography marginBottom='12px' className={classes.typography3}>
          Top Fleet Manager
        </Typography>

        {/* CHART */}
        <PieChart
          options={getPieChartOptions(
            topFleetManager.map((item) => item.name),
            topFleetManager.map((item) => item.color)
          )}
          series={topFleetManager.map((item) => item.value)}
        />

        {/* LIST DATA */}
        <Stack 
          spacing={0.5}
          alignItems='flex-start'
          width='100%'
          marginTop='12px'
        >
          {topFleetManager.map((item, index) => (
            <Stack 
              key={index}
              direction='row'
              alignItems='center'
              spacing={1}
            >
              <Stack
                width='16px'
                height='4px'
                borderRadius='4px'
                sx={{backgroundColor: item.color}}
              />

              <Typography className={classes.typography4}>
                {item.name}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>

      {/* TOP LOCATION */}
      <Stack alignItems='center' className={classes.rightContentRoot}>
        {/* TITLE */}
        <Typography marginBottom='12px' className={classes.typography3}>
          Top Location
        </Typography>

        {/* CHART */}
        <PieChart
          options={getPieChartOptions(
            topLocation.map((item) => item.name),
            topLocation.map((item) => item.color)
          )}
          series={topLocation.map((item) => item.value)}
        />

        {/* LIST DATA */}
        <Stack 
          spacing={0.5}
          alignItems='flex-start'
          width='100%'
          marginTop='12px'
        >
          {topLocation.map((item, index) => (
            <Stack 
              key={index}
              direction='row'
              alignItems='center'
              spacing={1}
            >
              <Stack
                width='16px'
                height='4px'
                borderRadius='4px'
                sx={{backgroundColor: item.color}}
              />

              <Typography className={classes.typography4}>
                {item.name}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default RightContent