// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  columnsMenuRoot: {
    '& .MuiList-root': {
      padding: '12px 4px',
      width: 250,
    },
  },
  columnsMenuItem: {
    textTransform: 'none',
    display: 'flex',
    padding: '2px 16px 2px 6px',
    borderRadius: 100,
  },
  columnApprovalStatusAccepted: {
    textTransform: 'none',
    borderColor: theme.palette.action.active,
    color: `${theme.palette.action.active} !important`,
    fontWeight: 400,
    borderRadius: 100,
  },
  columnApprovalStatusRejected: {
    color: theme.palette.action.disabledBackground,
    textTransform: 'none',
    borderRadius: 100,
  },
  columnApprovalStatusRequest: {
    textTransform: 'none',
    borderColor: theme.palette.info.main,
    color: `${theme.palette.info.main} !important`,
    fontWeight: 400,
    fontSize: 11,
    borderRadius: 100,
    '& .MuiSvgIcon-root': {
      width: '16px',
      height: '16px'
    },
    '&:hover': {
      cursor: 'pointer',
      borderColor: theme.palette.info.main,
    }
  },
}))

export default useStyles
