// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  headerIconToggle: {
    position: 'absolute',
    left: 20,
    transform: 'rotateY(0deg)',
    transition: 'transform 0.25s ease-in-out',
    color: theme.palette.common.white,
  },
  headerLogoCompany: {
    height: 20,
  },
  navigationItemButton: {
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 4,
  },
  navigationItemButtonActive: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.8),
    },
  },
  navigationItemIcon: {
    color: theme.palette.common.white,
    marginLeft: 4,
  },
  navigationItemIconActive: {
    color: theme.palette.primary.main,
  },
  navigationItemText: {
    '& .MuiTypography-root': {
      fontWeight: 500,
    },
  },
  logoutItem: {
    marginTop: 'auto',
    marginBottom: 16,
  },
  logoutButton: {
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.24),
    },
  },
  logoutAvatar: {
    '&.MuiAvatar-root': {
      width: 32,
      height: 32,
    },
  },
  logoutItemTextPrimary: {
    fontWeight: '600 !important',
    color: theme.palette.common.white,
  },
  logoutItemText: {
    '& p': {
      fontWeight: 400,
      fontSize: '12px !important',
      color: theme.palette.grey[100],
    },
  },
  logoutItemIconExtra: {
    marginLeft: 8,
  },
}))

export default useStyles