// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  rightContentRoot: {
    width: 264,
    boxShadow: theme.shadows[3],
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '16px 20px',
    borderRadius: 4,
  },
  leftContentRoot: {
    width: '100%',
    boxShadow: theme.shadows[3],
    height: '100%',
    padding: 20,
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  buttonContainer: {
    borderRadius: 4,
    cursor: 'pointer',
    padding: '4px 10px',
    '& .MuiTypography-root': {
      fontSize: 13,
      fontWeight: 500,
    },
    '& .MuiSvgIcon-root': {
      width: '14px',
    }
  },
  typography1: {
    fontSize: 14,
    fontWeight: 400,
  },
  typography2: {
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.secondary
  },
  typography3: {
    fontSize: 14,
    fontWeight: 500,
  },
  typography4: {
    fontSize: 12,
    fontWeight: 200,
  },
  movesByDepotTitle: {
    fontSize: 20,
    fontWeight: 600,
    color: theme.palette.text.secondary
  },
  listDepoContainer: {
    cursor: 'pointer',
    borderTop: '1px solid #0000001F',
    borderLeft: '1px solid #0000001F',
    borderRight: '1px solid #0000001F',
    padding: 16,
    borderRadius: 4,
    width: '100%',
    maxWidth: 250,
  },
  filterLocationContainer: {
    width: 180,
    backgroundColor: 'white',
    height: 44,
    padding: '10px 8px',
    boxShadow: theme.shadows[3],
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover' : {
      cursor: 'pointer'
    }
  },
  menuContainer: {
    '& .MuiMenuItem-root': {
      width: 154,
      borderRadius: 0,
      zoom: 0.85,
    },
    '& .MuiMenu-paper' : {
      borderRadius: '0px !important',
      marginLeft: '-0.5%',
      marginTop: 5
    }
  }
}))

export default useStyles
