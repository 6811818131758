// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  productLogo: {
    height: 24,
    marginBottom: 12,
  },
  pageTitle: {
    marginBottom: 12,
  },
  caption: {
    marginTop: 8,
    marginBottom: 28,
  },
  formInput: {
    marginBottom: 12,
  },
  formButtonContainer: {
    marginTop: 28,
    width: '100%',
  },
  formButton: {
    flex: 1,
    marginTop: 28,
  },
}))

export default useStyles
