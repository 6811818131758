// DATE AND TIME
import { format } from 'date-fns'
import moment from 'moment'

export const convertDate = (inputDate, inputFormat = 'dd MMM yyyy HH:mm:ss') => {
  if (!inputDate) return ''
  else {
    const date = (new Date(inputDate)).toLocaleString('en-US', {timeZone: 'Asia/Jakarta'})
    return date === 'Invalid Date' ? inputDate : format(new Date(date), inputFormat)
  }
}

export const getThisWeekDateAndTime = () => {
  return [
    moment().startOf('week').toDate(), 
    moment().endOf('week').toDate(),
  ]
}