// MUIS
import { grey } from '@mui/material/colors'
import { styled } from '@mui/material/styles'

// MUI DATA GRID
import { DataGridPro } from '@mui/x-data-grid-pro'

const CustomDataGridPro = styled(({ className, componentsProps, ...props }) => (
  <DataGridPro
    checkboxSelection
    //checkboxSelectionVisibleOnly
    rowHeight={48}
    headerHeight={48}
    pagination
    rowsPerPageOptions={[ 5, 10, 15, 25, 50, 100, 250, 500, 1000 ]}
    disableColumnMenu
    componentsProps={{
      ...componentsProps,
      pagination: {
        SelectProps: {
          MenuProps: {
            sx: {
              '& .MuiMenuItem-root': {
                fontSize: 12,
              },
              '@media only screen and (max-height: 820px)': {
                '& .MuiPaper-root': {
                  zoom: 1/0.85, // no zoom
                },
                '& .MuiList-root': {
                  zoom: 0.85, // zoom
                },
              }
            },
          },
        },
      },
    }}
    {...props}
    className={className}
  />
))(({ theme }) => ({
  border: 'none',
  fontSize: 12,
  color: theme.palette.text.primary,

  // HEADER
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-sortIcon': {
    color: theme.palette.primary.main,
  },
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: 0,
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 600,
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    boxShadow: 'none',
    backgroundColor: 'transparent',
  },
  '& .MuiDataGrid-iconSeparator': {
    display: 'none',
  },

  // COLUMN
  '& .MuiDataGrid-pinnedColumns': {
    boxShadow: 'none',
    backgroundColor: 'unset',
    '& .MuiDataGrid-cell': {
      padding: 0,
    },
  },
  '[data-field="actions"] > *': {
    display: 'none',
  },
  '.MuiDataGrid-row.Mui-hovered [data-field="actions"] > *': {
    display: 'flex',
  },

  // ROW
  '& .MuiDataGrid-row:hover': {
    backgroundColor: grey[100],
  },
  '& .MuiDataGrid-row.Mui-hovered': {
    backgroundColor: grey[100],
  },
  '& .MuiDataGrid-row.Mui-selected': {
    // NOTE: THIS MUST BE SET MANUALLY
    backgroundColor: '#FFF3F1',
  },
  '& .MuiDataGrid-row.Mui-selected:hover': {
    backgroundColor: grey[100],
  },
  '& .MuiDataGrid-row.Mui-selected.Mui-hovered': {
    backgroundColor: grey[100],
  },

  // CELL
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },

  // PAGINATION
  '& .MuiTablePagination-selectLabel': {
    fontSize: 12,
  },
  '& .MuiTablePagination-select': {
    fontSize: 12,
  },
  '& .MuiTablePagination-displayedRows': {
    fontSize: 12,
  },
  '& .MuiIconButton-root': {
    padding: 8,
  },

  '@media only screen and (max-height: 820px)': {
    '& .MuiToolbar-root': {
      zoom: 1/0.85, // no zoom
    },
    '& .MuiTablePagination-selectLabel': {
      zoom: 0.85,
    },
    '& .MuiTablePagination-displayedRows': {
      zoom: 0.85,
    },
    '& .MuiTablePagination-actions': {
      zoom: 0.85,
    },
    '& .MuiDataGrid-footerContainer .MuiInputBase-root': {
      zoom: 1/0.85,
    },
    '& .MuiDataGrid-footerContainer .MuiTablePagination-select': {
      zoom: 0.85,
      fontSize: 10,
    },
    '& .MuiDataGrid-footerContainer .MuiSelect-nativeInput': {
      zoom: 0.85,
    },
    '& .MuiDataGrid-footerContainer .MuiSvgIcon-root': {
      zoom: 0.85,
    }
  }
}))

export default CustomDataGridPro