import { useState } from 'react'

// COMPONENTS
import AppBar from 'components/AppBar/AppBar'
import LeftContent from './LeftContent/LeftContent'
import RightContent from './RightContent/RightContent'

// MUIS
import {
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material'

// MUI ICONS
import IconArrow from '@mui/icons-material/KeyboardArrowDown'

// STYLES
import useStyles from './homeUseStyles'

const Home = () => {
  const classes = useStyles()

  const [selectedLocation, setSelectedLocation] = useState('Semarang')

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const locationList = ['Semarang', 'Jakarta', 'Surabaya', 'Medan', 'All']

  // HANDLE MENU ITEM CLICK
  const handleMenuItemClick = (inputItem) => {
    setSelectedLocation(inputItem)
    setAnchorEl(null)
  }

  // EXTRA COMPONENT
  const filterLocationComponent = () => {
    return (
      <>
        <Stack 
          className={classes.filterLocationContainer}
          onClick={(event) => setAnchorEl(event.currentTarget)} 
        >
          <Typography variant='subtitle2' sx={{color:'#00000099'}}>
            {selectedLocation}
          </Typography>

          <IconArrow sx={{color:'#00000099'}}/>
        </Stack>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          className={`${classes.menuContainer} no-zoom`}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {locationList.map((item, index) => (
            <MenuItem key={index} onClick={() => handleMenuItemClick(item)}>{item}</MenuItem>
          ))}
        </Menu>
      </>
    )
  }

  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
      >
        {/* APPBAR */}
        <AppBar
          hasSearch={false}
          pageTitle='Overview'
          hasFab={false}
          extraComponent={filterLocationComponent()}
        />
      </Stack>

      {/* CONTENT */}
      <Stack
        justifyContent='space-between' 
        direction='row'
        spacing={2.5}
        height='91.5%'
        backgroundColor='#FFFFFF'
      >
        {/* LEFT CONTENT */}
        <LeftContent selectedLocation={selectedLocation}/>

        {/* RIGHT CONTENT */}
        {/* <RightContent/> */}
      </Stack>
    </>
  )
}

export default Home