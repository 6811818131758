import { useState, useContext } from 'react'

// COMPONENTS
import LoadingPaper from 'components/LoadingPaper/LoadingPaper'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import {
  Button, FormControl,
  Input, IconButton, InputAdornment,
  InputLabel, Stack
} from '@mui/material'
import Typography from '@mui/material/Typography'

// MUI ICONS
import AccountIcon from '@mui/icons-material/Person'
import PasswordIcon from '@mui/icons-material/Lock'
import IconVisibility from '@mui/icons-material/Visibility'
import IconVisibilityOff from '@mui/icons-material/VisibilityOff'

// RAMDA
import { isEmpty } from 'ramda'

// SERVICES
import { changePassword, editUserProfile } from 'services/admins'

// STYLES
import useLayoutStyles from 'styles/layoutPrivate'
import useStyles from './profileUseStyles'

const Profile = () => {
  const { auth, setAuth, setSnackbarObject } = useContext(AllPagesContext)
  const layoutClasses = useLayoutStyles()
  const classes = useStyles()

  const initialDrawerList = [
    {
      title: 'Account',
      isActived : true,
      icon: <AccountIcon sx={{color: '#0000008A'}}/>,
    },
    {
      title: 'Security',
      isActived : false,
      icon: <PasswordIcon sx={{color: '#0000008A'}}/>,
    },
  ]

  const currentAccount = {
    fullName: auth.fullName,
    phoneNumber: auth.phoneNumber,
    email: auth.email,
  }
  
  const initialResetForm = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  }

  const [drawerList, setDrawerList] = useState(initialDrawerList)
  const [selectedDrawer, setSelectedDrawer] = useState('Account')
  const [accountData, setAccountData] = useState(currentAccount)
  const [resetForm, setResetForm] = useState(initialResetForm)
  const [showPassword, setShowPassword] = useState(false)
  
  const handleAccountFormChange = (event) => {
    setAccountData(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  const handleResetFormChange = (event) => {
    setResetForm(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }
  
  const handleDrawerListClick = (inputItem) => {
    const newList = [...drawerList].map((item) => {
      if(item.title === inputItem.title) item.isActived = true
      else item.isActived = false

      return item
    })
    setDrawerList(newList)
    setSelectedDrawer(inputItem.title)
  } 

  const handleFormButtonClick = async (inputEvent) => {
    const abortController = new AbortController()

    // EDIT USER PROFILE
    if (selectedDrawer === 'Account') {
      const resultEditUserProfile = await editUserProfile(
        abortController.signal, 
        {
          email: inputEvent.email,
          full_name: inputEvent.fullName,
          phone_number: inputEvent.phoneNumber,
        },
        auth.accessToken
      )

      if (resultEditUserProfile.status === 200){
        setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: 'Successfully update profile',
        })
        setAuth({
          ...auth,
          fullName: resultEditUserProfile?.data?.fullName,
          phoneNumber: resultEditUserProfile?.data?.phoneNumber,
          email: resultEditUserProfile?.data?.email,
        })
      }
      else {
        setSnackbarObject({
          open: true,
          severity: 'error',
          title: 'Failed to update profile',
        })
      }
    }
    // EDIT PASSWORD
    else if (selectedDrawer === 'Security') {
      const resultChangePassword = await changePassword(
        abortController.signal, 
        {
          old_password: inputEvent.oldPassword,
          new_password: inputEvent.newPassword,
          confirm_new_password: inputEvent.confirmPassword,
        },
        auth.accessToken
      )

      if (resultChangePassword.status === 200){
        setSnackbarObject({
          open: true,
          severity: 'success',
          title: '',
          message: 'Successfully change password',
        })
        setResetForm(initialResetForm)
      }
      else {
        setSnackbarObject({
          open: true,
          severity: 'error',
          title: 'Failed to change password',
        })
      }
    }
  }

  return (
    <Stack>
      {/* TITLE */}
      <Typography
        variant='h6'
        marginBottom='16px'
        className={layoutClasses.paperTitle}
      >
        Profile settings
      </Typography>

      {/* PROFILE DRAWER */}
      <LoadingPaper
        isLoading={false}
        className={classes.mainContent}
      >
        <Stack
          direction='column'
          marginTop='8px'
          marginRight='20px'> 
          {drawerList.map((item, index) => (
            <Stack
              key={index}
              className={classes.drawer}
              width='200px'
              height='48px'
              borderRadius='4px'
              direction='row'
              alignItems='center'
              padding='16px'
              marginBottom='8px'
              backgroundColor= {item.isActived ? '#F9664914' : 'white'}
              onClick={() => handleDrawerListClick(item)}
            >
              {item.icon}
              <Typography width='100%' textAlign={'center'}>
                {item.title}
              </Typography>
            </Stack>
          ))}
        </Stack>

        {/* ACCOUNT */}
        {selectedDrawer === 'Account' &&
          <LoadingPaper isLoading={false}>
            <Stack padding='16px'>
              <Stack direction='column'>
                <Typography marginBottom='32px'>Edit profile</Typography>
                {/* FULL NAME */}
                <FormControl 
                  variant='standard'
                  className='formTextField'
                  color='secondary'
                >
                  <InputLabel>
                    Full name
                  </InputLabel>
                  <Input
                    type='text'
                    name='fullName'
                    value={accountData.fullName}
                    onChange={handleAccountFormChange}
                  />

                  {/* CHANGE FULL NAME HELPER TEXT */}
                  {/* TO DO: IMPLEMENT HELPER */}
                </FormControl>

                {/* PHONE NUMBER */}
                <FormControl 
                  variant='standard'
                  className='formTextField'
                  color='secondary'
                >
                  <InputLabel>
                    Phone number
                  </InputLabel>
                  <Input
                    type='text'
                    name='phoneNumber'
                    value={accountData.phoneNumber}
                    onChange={handleAccountFormChange}
                  />
                </FormControl>

                {/* EMAIL */}
                <FormControl 
                  variant='standard'
                  className='formTextField'
                  color='secondary'
                >
                  <InputLabel>
                    Email
                  </InputLabel>
                  <Input
                    type='email'
                    name='email'
                    value={accountData.email}
                    onChange={handleAccountFormChange}
                  />
                </FormControl>
              </Stack>
              <Stack
                direction='row'
                padding='8px'
                marginTop='10px'
              >
                {/* BUTTONS */}
                <Stack marginRight='16px'>
                  <Button
                    disabled={(!isEmpty(accountData.fullName) && !isEmpty(accountData.phoneNumber)) ? false : true}
                    variant='contained'
                    onClick={() => handleFormButtonClick(accountData)}
                  >
                    Save changes
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </LoadingPaper>
        }

        {/* SECURITY */}
        {selectedDrawer === 'Security' &&
          <LoadingPaper isLoading={false}>
            <Stack padding='16px'>
              <Stack direction='column'>
                {/* FORM */}
                <Typography marginBottom='32px'>Change password</Typography>

                {/* OLD PASSWORD FORM */}
                <FormControl 
                  variant='standard'
                  className='formTextField'
                  color='secondary'
                >
                  <InputLabel>
                    Old Password
                  </InputLabel>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    name='oldPassword'
                    value={resetForm.oldPassword}
                    onChange={handleResetFormChange}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setShowPassword(current => !current)}
                          edge='end'
                        >
                          {showPassword ? <IconVisibilityOff/> : <IconVisibility/>}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                {/* NEW PASSWORD FORM */}
                <FormControl 
                  variant='standard'
                  className='formTextField'
                  color='secondary'
                >
                  <InputLabel>
                    New Password
                  </InputLabel>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    name='newPassword'
                    value={resetForm.newPassword}
                    onChange={handleResetFormChange}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setShowPassword(current => !current)}
                          edge='end'
                        >
                          {showPassword ? <IconVisibilityOff/> : <IconVisibility/>}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>

                {/* CONFIRM PASSWORD  FORM */}
                <FormControl 
                  variant='standard'
                  className='formTextField'
                  color='secondary'
                >
                  {/* CONFIRM PASSWORD INPUT LABEL */}
                  <InputLabel>
                    Confirm New Password
                  </InputLabel>

                  {/* CONFIRM PASSWORD  INPUT */}
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    name='confirmPassword'
                    value={resetForm.confirmPassword}
                    onChange={handleResetFormChange}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => setShowPassword(current => !current)}
                          edge='end'
                        >
                          {showPassword ? <IconVisibilityOff/> : <IconVisibility/>}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Stack>
              <Stack
                direction='row'
                padding='8px'
                marginTop='10px'
              >
                {/* BUTTONS */}
                <Stack marginRight='16px'>
                  <Button
                    disabled={(!isEmpty(resetForm.oldPassword) && !isEmpty(resetForm.newPassword) && !isEmpty(resetForm.confirmPassword)) ? false : true} 
                    variant='contained'
                    onClick={() => handleFormButtonClick(resetForm)}
                  >
                    Save
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </LoadingPaper>
        }
      </LoadingPaper>
    </Stack>
  )
}

export default Profile