// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialogDateRangePicker: {
    '& .MuiPaper-root': {
      maxWidth: 'unset',
    },
  },
}))

export default useStyles