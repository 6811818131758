import { useState } from 'react'

// MUIS
import { Stack, Typography } from '@mui/material'

// MUI ICONS
import CheckIcon from '@mui/icons-material/Check'

// STYLES
import useStyles from '../homeUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string'

const MovesByDepot = ({setFilteredDay, listData, handleMBDListItemClick}) => {
  const classes = useStyles()

  const initialListButton = [
    {
      title: '7 DAYS',
      isActived: false,
    },
    {
      title: '30 DAYS',
      isActived: true,
    },
  ]

  const [dayButtonList, setDayButtonList] = useState(initialListButton)

  const handleDayButtonClick = (inputItem) => {
    const newListBtn = [...dayButtonList].map((item) => {
      if(item.title === inputItem.title) item.isActived = true
      else  item.isActived = false

      return item
    })
    setDayButtonList(newListBtn)

    if(inputItem.title === '7 DAYS') setFilteredDay(7)
    else setFilteredDay(30)
  }

  return (
    <>
      {/* TITLE & BUTTON */}
      <Stack
        justifyContent='space-between'
        direction='row'
        alignItems='flex-end'
      >
        {/* TOTAL MOVES */}
        <Typography className={classes.movesByDepotTitle}>
          Moves by depot
        </Typography>

        {/* BUTTONS */}
        <Stack direction='row' spacing={1}>
          {dayButtonList.map((item, index) => (
            <Stack 
              key={index}
              onClick={() => handleDayButtonClick(item)}
              className={classes.buttonContainer}
              alignItems='center'
              direction='row' 
              spacing={1}
              sx={{
                backgroundColor: item.isActived? '#F9664914' : 'unset'
              }}
            >
              {item.isActived && <CheckIcon sx={{color:item.isActived ? '#F96649' : 'black'}}/>}
              <Typography color={item.isActived ? '#F96649' : 'black'}>
                {item.title}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>

      {/* LIST DEPO */}
      <Stack 
        direction='row'
        marginTop='20px'
        justifyContent={listData.length === 5 ? 'space-between' : 'flex-start'}
        spacing={2}
      >
        {listData.map((item, index) => (
          <Stack 
            onClick={() => handleMBDListItemClick(item)}
            key={index} 
            className={classes.listDepoContainer}
            sx={(theme) => ({borderBottom : item.isActived ? `4px solid ${item.color}` : `1px solid ${theme.palette.divider}`})}
          >
            <Typography className={classes.typography2}>
              {item?.name}
            </Typography>
  
            <Typography marginTop='8px' variant='h5'>
              {addSeparatorsForNumber(item?.totalMoves)}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </>
  )
}

export default MovesByDepot