//APIS
import { axiosPrivate } from 'apis/axios'

export const getImpersonateUser = async (
  inputSignal,
  inputEmail, 
  inputToken,
) => {
  try {
    const response = await axiosPrivate.get(
      `/api/switchUser/impersonate/forced?username=${inputEmail}`, 
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      },
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}