// APEX CHART
import ReactApexChart from 'react-apexcharts'

// MUIS
import Stack from '@mui/material/Stack'

const Chart = (props) => {
  const {
    options,
    series,
  } = props

  return (
    <Stack>
      <ReactApexChart
        options={options}
        series={series}
        type='bar'
        width='100%'
        height={360}
      />
    </Stack>
  )
}

export default Chart