import { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

// COMPONENTS
import Drawer from 'components/Drawer/Drawer'
import LoadingComponent from 'components/LoadingComponent/LoadingComponent'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'

// MUIS
import CssBaseline from '@mui/material/CssBaseline'
import Stack from '@mui/material/Stack'

// SERVICES
import { getUserProfile } from 'services/admins'

// STYLES
import useStyles from './privateUseStyles'

// UTILITIES
import { setUserProfileToLocalStorage } from 'utilities/localStorage'
import { doesSuccessfullyCallTheApi } from 'utilities/validation'

const Private = (props) => {
  const { children } = props

  const classes = useStyles()

  const { pageRef } = useContext(PrivateLayoutContext)
  const { auth, setAuth } = useContext(AllPagesContext)

  const loadUserProfileData = async (inputIsMounted, inputAbortController) => {
    const resultUserProfile = await getUserProfile(inputAbortController.signal, auth.accessToken)

    if (doesSuccessfullyCallTheApi(resultUserProfile.status) && inputIsMounted) {
      const userObject = {
        ...auth,
        ...resultUserProfile.data,
      }

      setUserProfileToLocalStorage(userObject)
      setAuth(userObject)
    }

    else if(resultUserProfile.status === 403 && resultUserProfile?.data?.error === 'Forbidden') {
      setAuth({})
    }
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()
  
    loadUserProfileData(isMounted, abortController)
  
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  return (
    <Stack 
      direction='row'
      className={`${classes.root} no-zoom`}
    >
      <CssBaseline/>

      {/* DRAWER */}
      <Drawer/>

      {/* CONTENT CONTAINER */}
      <Stack
        component='main'
        className={`${classes.contentContainer} zoom`}
        ref={pageRef}
      >
        {children}
      </Stack>

      {/* LOADING COMPONENT */}
      <LoadingComponent/>
    </Stack>
  )
}

Private.defaultProps = {}

Private.propTypes = { children: PropTypes.node.isRequired }

export default Private
