// APEX CHART
import ReactApexChart from 'react-apexcharts'

// MUIS
import Stack from '@mui/material/Stack'

const PieChart = (props) => {
  const {
    options,
    series,
  } = props

  return (
    <Stack className='no-zoom'>
      <ReactApexChart
        options={options}
        series={series}
        type='pie'
        width='100%'
        height='200'
      />
    </Stack>
  )
}

export default PieChart