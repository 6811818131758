//APIS
import { axiosPrivate } from 'apis/axios'

export const getTopLocation = async (
  inputSignal,
  inputToken,
) => {
  try {
    const response = await axiosPrivate.get(
      '/api/applicants/count-location', 
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      },
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getTopFleetManager = async (
  inputSignal,
  inputToken,
) => {
  try {
    const response = await axiosPrivate.get(
      '/api/applicants/count-fleet-manager', 
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      },
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getTotalMoves = async (
  inputSignal,
  inputToken,
  inputQueryParams
) => {
  try {
    const response = await axiosPrivate.get(
      `/api/applicants/count-total-moves?duration=${inputQueryParams.duration}&location=${inputQueryParams.location}`, 
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      },
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getMovesByDepot = async (
  inputSignal,
  inputToken,
  inputQueryParams
) => {
  try {
    const response = await axiosPrivate.get(
      `/api/applicants/count-gate-moves-by-depot?duration=${inputQueryParams.duration}&location=${inputQueryParams.location}`,
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      },
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const downloadOverview = async (
  inputSignal,
  inputToken,
  inputQueryParams
) => {
  try {
    const response = await axiosPrivate.get(
      `api/applicants/count-total-moves/download?duration=${inputQueryParams.duration}&location=${inputQueryParams.location}`,
      { 
        signal : inputSignal,
        headers: {'Authorization': `Bearer ${inputToken}`},
        responseType: 'blob'
      }
    )
    return response
  }
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}