// MOMENT
import moment from 'moment'

// MUIS
import { Stack, Typography } from '@mui/material'

// MUI ICONS
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'

const ChartTooltip = (props) => {
  const { dataPointIndex, inputToolTipData } = props

  // FUNCTION CALCULATING OTHERS DATA
  let tempSumData = 0
  const calculateTopFiveData = inputToolTipData[dataPointIndex]?.fleet_count.slice(0,5).forEach((item) => {
    tempSumData += item.count
  })
  const totalOthers = inputToolTipData[dataPointIndex]?.total - tempSumData

  return (
    <Stack style={{
      width: 220,
      borderRadius: 8,
      backgroundColor: 'white',
      boxShadow: '1px 1px 6px 0px #0000003B',
      padding: '0px 12px',
    }}>
      {/* DATE AND TOTAL MOVES */}
      <Stack style={{
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}>
        <Typography style={{
          fontSize: '10px',
          fontWeight: 500,
        }}>
          {moment(inputToolTipData[dataPointIndex]?.tx_date).format('DD MMM, YYYY')}
        </Typography>
        
        <Typography style={{
          fontSize: '10px',
          fontWeight: 600,
        }}>
          {inputToolTipData[dataPointIndex]?.total}
        </Typography>
      </Stack>

      {/* FLEET MANAGER LIST */}
      {inputToolTipData[dataPointIndex]?.fleet_count.slice(0,5).map((item, index) => (
        <Stack 
          key={index}
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          {/* TITLE */}
          <Stack style={{
            flexDirection: 'row',
            alignItems: 'center',
            color: '#00000099'
          }}>
            <FiberManualRecordIcon style={{
              width: '6px',
              height : '6px'
            }}/>

            <Typography style={{
              fontSize: '10px',
              fontWeight: 600,
              marginLeft: '6px'
            }}>
              {item?.fleetManager} 
            </Typography>
            
          </Stack>

          {/* TOTAL */}
          <Typography style={{
            fontSize: '10px',
            fontWeight: 600
          }}>
            {item?.count}
          </Typography>
        </Stack>
      ))}

      {/* OTHERS */}
      {inputToolTipData[dataPointIndex]?.fleet_count.length > 4 && <Stack 
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between'
        }}
      >
        {/* TITLE */}
        <Stack style={{
          flexDirection: 'row',
          alignItems: 'center',
          color: '#00000099'
        }}>
          <FiberManualRecordIcon style={{
            width: '6px',
            height : '6px'
          }}/>

          <Typography style={{
            fontSize: '10px',
            fontWeight: 600,
            marginLeft: '6px'
          }}>
            Others
          </Typography>
            
        </Stack>

        {/* TOTAL */}
        <Typography style={{
          fontSize: '10px',
          fontWeight: 600
        }}>
          {totalOthers}
        </Typography>
      </Stack>}
      
    </Stack>
  )
}

export default ChartTooltip