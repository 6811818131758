import { useState } from 'react'
import PropTypes from 'prop-types'

// COMPONENTS
import DateRangeTimePicker from 'components/DateRangeTimePicker/DateRangeTimePicker'

// CUSTOM COMPONENTS
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Badge from '@mui/material/Badge'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconArrowDropDown from '@mui/icons-material/ArrowDropDown'
import IconArrowDropUp from '@mui/icons-material/ArrowDropUp'
import IconDateRangeRounded from '@mui/icons-material/DateRangeRounded'
import IconDownload from '@mui/icons-material/Download'
import IconFilterAltRounded from '@mui/icons-material/FilterAltRounded'
import IconViewColumnRounded from '@mui/icons-material/ViewColumnRounded'

// STYLES
import useLayoutStyles from 'styles/layoutPrivate'
import useStyles from './dataGridFiltersUseStyles'

const DataGridFilters = (props) => {
  const { 
    // COLUMN
    columns,
    selectedColumnList,
    handleColumnsMenuItemClick,
    // FILTER
    isFilterOn, 
    setIsFilterOn,
    // GROUP BY
    groupByList,
    selectedGroupBy,
    setSelectedGroupBy,
    // TOTAL
    totalRows,
    // DATE RANGE TIME
    dateRangeValue,
    dateRangeDateFormat,
    dateRangeTimeFormat,
    isWithTimePicker,
    isDateRangeTimePickerOpen, 
    setIsDateRangeTimePickerOpen,
    handleSelectDateRangePickerButtonClick,
    handleCancelDateRangePickerButtonClick,
    // DOWNLOAD
    isDownloadEnabled,
    handleDownloadButtonClick,
  } = props

  const layoutClasses = useLayoutStyles()
  const classes = useStyles()

  const [ groupByMenuAnchor, setGroupByMenuAnchor ] = useState(null)
  const [ columnsMenuAnchor, setColumnsMenuAnchor ] = useState(null)

  const handleGroupByMenuItemClick = (inputItem, inputIndex) => {
    setGroupByMenuAnchor(null)
    setSelectedGroupBy(inputItem)
  }

  return (
    <Stack 
      direction='row'
      alignItems='center'
      className={classes.root}
    >
      {/* GROUP BY TEXT */}
      <Typography
        variant='subtitle2'
        className={classes.groupByText}
      >
        Group By:
      </Typography>

      {/* GROUP BY INPUT */}
      <Input
        className={classes.gruopByInput}
        value={selectedGroupBy.title}
        onChange={(event) => setSelectedGroupBy(event.target.value)}
        endAdornment={
          groupByMenuAnchor ?
            <IconArrowDropUp className={classes.groupByIcon}/> :
            <IconArrowDropDown className={classes.groupByIcon}/>
        }
        onClick={(event) => setGroupByMenuAnchor(event.currentTarget)}
      />

      {/* GROUP BY MENU */}
      <Menu
        anchorEl={groupByMenuAnchor}
        open={Boolean(groupByMenuAnchor)}
        onClose={() => setGroupByMenuAnchor(null)}
        className={classes.groupByMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {groupByList.map((item, index) => (
          <MenuItem 
            key={index}
            onClick={() => handleGroupByMenuItemClick(item, index)}
            className={selectedGroupBy.title === item.title ? 'colorPrimary' : ''}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>

      {/* TOTAL APPLICANT */}
      <Typography 
        variant='body2'
        className={classes.totalRow}
      >
        Total: {totalRows} Applicants
      </Typography>

      {/* DATE RANGE TIME PICKER BUTTON */}
      {/* <CustomTooltipBlack 
        title='Filter by Date and Time Ranges'
        placement='top'
      >
        <IconButton 
          className={classes.iconButton}
          onClick={() => setIsDateRangeTimePickerOpen(true)}
          color='primary'
          size='small'
        >
          <Badge 
            color={dateRangeValue.length > 0 ? 'secondary' : 'default'} 
            variant='dot'
          >
            <IconDateRangeRounded fontSize='small'/>
          </Badge>
        </IconButton>
      </CustomTooltipBlack> */}

      {/* DATE RANGE TIME PICKER DIALOG */}
      {/* <Dialog 
        open={isDateRangeTimePickerOpen}
        onClose={() => setIsDateRangeTimePickerOpen(false)} 
        className={layoutClasses.dialogDateRangePicker}
      >
        <DateRangeTimePicker
          value={dateRangeValue}
          dateFormat={dateRangeDateFormat}
          timeFormat={dateRangeTimeFormat}
          isWithTimePicker={isWithTimePicker}
          handleSelectButtonClick={handleSelectDateRangePickerButtonClick}
          handleCancelButtonClick={handleCancelDateRangePickerButtonClick}
        />
      </Dialog> */}

      {/* DOWNLOAD BUTTON */}
      <CustomTooltipBlack 
        title={isDownloadEnabled ? 'Download' : ''} 
        placement='top'
      >
        <IconButton 
          className={classes.iconButton}
          onClick={handleDownloadButtonClick}
          disabled={!isDownloadEnabled}
          color='primary'
          size='small'
        >
          <IconDownload fontSize='small'/>
        </IconButton>
      </CustomTooltipBlack>

      {/* FILTER ICON */}
      <CustomTooltipBlack 
        title='Filters' 
        placement='top'
      >
        <IconButton 
          className={classes.iconButton}
          onClick={() => setIsFilterOn(current => !current)}
          color='primary'
          size='small'
        >
          <Badge 
            color={isFilterOn ? 'secondary' : 'default'} 
            variant='dot'
          >
            <IconFilterAltRounded fontSize='small'/>
          </Badge>
        </IconButton>
      </CustomTooltipBlack>

      {/* CUSTOMIZE COLUMNS ICON */}
      <CustomTooltipBlack 
        title='Customize Columns' 
        placement='top'
      >
        <IconButton 
          className={classes.iconButton}
          onClick={(event) => setColumnsMenuAnchor(event.currentTarget)}
          color='primary'
          size='small'
        >
          <IconViewColumnRounded fontSize='small'/>
        </IconButton>
      </CustomTooltipBlack>

      {/* CUSTOMIZE COLUMNS MENU */}
      <Menu
        anchorEl={columnsMenuAnchor}
        open={Boolean(columnsMenuAnchor)}
        onClose={() => setColumnsMenuAnchor(null)}
        className={`${classes.columnsMenuRoot} no-zoom`}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack className='zoom'>
          {/* TITLE */}
          <Typography
            variant='subtitle2'
            className={classes.columnsMenuTitle}
          >
            Columns
          </Typography>

          {/* COLUMN ITEMS */}
          {columns.map((item, index) => (
            (item.field !== 'actions') &&
            <MenuItem 
              key={index}
              className={classes.columnsMenuItem}
              onClick={() => handleColumnsMenuItemClick(item, index)}
            >
              <Checkbox checked={!selectedColumnList[index].hide}/>
              <Typography
                variant='subtitle2'
                className={classes.columnsMenuText}
              >
                {item.headerName}
              </Typography>
            </MenuItem>
          ))}
        </Stack>
      </Menu>
    </Stack>
  )
}

DataGridFilters.defaultProps = {
  // COLUMN
  columns: [],
  selectedColumnList: [],
  // FILTER
  isFilterOn: false,
  // GROUP BY ROW
  groupByList: [],
  selectedGroupBy: {},
  // TOTAL
  totalRows: 0,
  // DATE RANGE VALUE
  dateRangeValue: [],
  isDateRangeTimePickerOpen: false,
  // DOWNLOAD
  isDownloadEnabled: false,
}

DataGridFilters.propTypes = {
  // COLUMN
  columns: PropTypes.array.isRequired,
  selectedColumnList: PropTypes.array.isRequired,
  handleColumnsMenuItemClick: PropTypes.func.isRequired,
  // FILTER
  isFilterOn: PropTypes.bool.isRequired,
  setIsFilterOn: PropTypes.func.isRequired,
  // GROUP BY ROW
  groupByList: PropTypes.array.isRequired,
  selectedGroupBy: PropTypes.object.isRequired,
  setSelectedGroupBy: PropTypes.func.isRequired,
  // TOTAL
  totalRows: PropTypes.number.isRequired,
  // DATE RANGE VALUE
  dateRangeValue: PropTypes.array.isRequired,
  isDateRangeTimePickerOpen: PropTypes.bool.isRequired,
  setIsDateRangeTimePickerOpen: PropTypes.func.isRequired,
  handleSelectDateRangePickerButtonClick: PropTypes.func.isRequired,
  handleCancelDateRangePickerButtonClick: PropTypes.func.isRequired,
  // DOWNLOAD
  isDownloadEnabled: PropTypes.bool.isRequired,
  handleDownloadButtonClick: PropTypes.func.isRequired,
}

export default DataGridFilters
