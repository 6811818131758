import { useState } from 'react'

// MUIS
import { Stack, Typography } from '@mui/material'

// MUI ICONS
import CheckIcon from '@mui/icons-material/Check'

// STYLES
import useStyles from '../homeUseStyles'

// UTILS
import { addSeparatorsForNumber } from 'utilities/string'

const TotalMoves = ({setFilteredDay, totalMoves}) => {
  const classes = useStyles()
  const initialListButton = [
    {
      title: '7 DAYS',
      isActived: false,
    },
    {
      title: '30 DAYS',
      isActived: true,
    },
  ]

  const [dayButtonList, setDayButtonList] = useState(initialListButton)

  const handleDayButtonClick = (inputItem) => {
    const newListBtn = [...dayButtonList].map((item) => {
      if(item.title === inputItem.title) item.isActived = true
      else  item.isActived = false

      return item
    })
    setDayButtonList(newListBtn)

    if(inputItem.title === '7 DAYS') setFilteredDay(7)
    else setFilteredDay(30)
  }

  return (
    <>
      {/* TITLE */}
      <Typography className={classes.typography1}>
        Total Moves 
      </Typography>

      {/* TOTAL MOVES & BUTTON */}
      <Stack
        justifyContent='space-between'
        direction='row'
        marginTop='10px'
        alignItems='flex-end'
      >
        {/* TOTAL MOVES */}
        <Typography variant='h4'>
          {addSeparatorsForNumber(totalMoves)}
        </Typography>

        {/* BUTTONS */}
        <Stack direction='row' spacing={1}>
          {dayButtonList.map((item, index) => (
            <Stack 
              key={index}
              onClick={() => handleDayButtonClick(item)}
              className={classes.buttonContainer}
              alignItems='center'
              direction='row' 
              spacing={1}
              sx={{
                backgroundColor: item.isActived? '#F9664914' : 'unset'
              }}
            >
              {item.isActived && <CheckIcon sx={{color:item.isActived ? '#F96649' : 'black'}}/>}
              <Typography color={item.isActived ? '#F96649' : 'black'}>
                {item.title}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </Stack>

    </>
  )
}

export default TotalMoves