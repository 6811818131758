import { useState, useContext } from 'react'
import PropTypes from 'prop-types'

// CONTEXTS
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'

// CUSTOM COMPONENTS
import CustomAppBar from 'components/Customs/CustomAppBar'
import CustomTooltipBlack from 'components/Customs/CustomTooltipBlack'

// MUIS
import Fab from '@mui/material/Fab'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconAdd from '@mui/icons-material/Add'
import IconClose from '@mui/icons-material/Close'
import IconSearch from '@mui/icons-material/Search'

// STYLES
import useStyles from './appBarUseStyles'

const AppBar = (props) => {
  const {
    hasFab,
    pageTitle,
    search, setSearch,
    hasSearch,
    extraComponent,
  } = props

  const classes = useStyles()

  const { setIsDialogAddOrEditOpen } = useContext(PrivateLayoutContext)

  const [ isSearchOpen, setIsSearchOpen ] = useState(false)

  return (
    <CustomAppBar 
      position='relative' 
      className='zoom'
    >
      <Toolbar className={classes.toolbar}>
        {/* FAB */}
        {hasFab &&
        <Fab 
          color='primary'
          onClick={() => setIsDialogAddOrEditOpen(true)}
          className={classes.fab}
        >
          <IconAdd/>
        </Fab>}

        {/* TITLE */}
        <Typography 
          variant='h6' 
          className='marginRightAuto'
        >
          {pageTitle}
        </Typography>

        {/* SEARCH */}
        {hasSearch &&
        <Stack 
          direction='row'
          className={classes.search}
        >
          {/* SEARCH ICON */}
          <CustomTooltipBlack
            title='Search'
            placement='bottom'
          >
            <IconButton onClick={() => setIsSearchOpen(true)}>
              <IconSearch/>
            </IconButton>
          </CustomTooltipBlack>

          {/* SEARCH INPUT */}
          <Input 
            className={isSearchOpen ? classes.searchInputWide : classes.searchInputNarrow}
            placeholder='Search'
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            endAdornment={
              // ICON CLOSE
              <InputAdornment position='end'>
                <IconClose
                  onClick={() => setIsSearchOpen(false)}
                  className='cursorPointer'
                />
              </InputAdornment>
            }
          />
        </Stack>}

        {/* EXTRA COMPONENT */}
        {extraComponent}
      </Toolbar>
    </CustomAppBar>
  )
}

AppBar.defaultProps = {
  search: '',
  pageTitle: '',
  hasFab: true,
  hasSearch: true,
}

AppBar.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func,
  pageTitle: PropTypes.string.isRequired,
  hasFab: PropTypes.bool.isRequired,
  hasSearch: PropTypes.bool.isRequired,
  extraComponent: PropTypes.node,
}

export default AppBar