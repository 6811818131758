import PropTypes from 'prop-types'

// MUIS
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'

// STYLES
import useStyles from './authenticationUseStyles'

const Authentication = (props) => {
  const { children } = props

  const classes = useStyles()

  return (
    <Stack className={`${classes.root} no-zoom`}>
      <Paper className={classes.content}>
        {/* CHILDREN */}
        {children}
      </Paper>
    </Stack>
  )
}

Authentication.defaultProps = {}

Authentication.propTypes = { children: PropTypes.node.isRequired }

export default Authentication