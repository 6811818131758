// MUI ICONS
import IconPersonAddAlt1 from '@mui/icons-material/PersonAddAlt1'
import HomeIcon from '@mui/icons-material/Home'

export const drawerNavigationList = [
  {
    title: 'Overview',
    icon: HomeIcon,
    path: '/',
  },
  {
    title: 'Applicant',
    icon: IconPersonAddAlt1,
    path: '/applicants',
  },
]