// PAGES
import Applicants from 'pages/Applicants/Applicants'
import Profile from 'pages/Profile/Profile'
import SignIn from 'pages/SignIn/SignIn'
import Home from 'pages/Home/Home'

const routes = [
  // AUTHENTICATION
  {
    path: '/sign-in',
    element: <SignIn/>,
    routeType: 'authentication',
  },
  // PRIVATE
  {
    path: '/applicants',
    element: <Applicants/>,
    routeType: 'private',
  },
  {
    path: '/',
    element: <Home/>,
    routeType: 'private',
  },
  {
    path: '/profile',
    element: <Profile/>,
    routeType: 'private',
  },
]

export default routes