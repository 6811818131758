//APIS
import { axiosPrivate } from 'apis/axios'

// QUERY
import { stringify } from 'query-string'

export const postApplicantList = async (
  inputSignal,
  inputParams, 
  inputBodyParams,
  inputToken,
) => {
  try {
    const response = await axiosPrivate.post(
      `/api/applicants/search?${stringify(inputParams)}`, 
      inputBodyParams,
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      },
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const putUpdateAccountStatus = async (
  inputSignal,
  inputApplicantId, 
  inputStatus,
  inputToken,
) => {
  try {
    const response = await axiosPrivate.put(
      `/api/applicants/update-account-status/${inputApplicantId}/${inputStatus}`,
      {},
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      },
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const putUpdateApprovalStatus = async (
  inputSignal,
  inputApplicantId, 
  inputStatus,
  inputToken,
) => {
  try {
    const response = await axiosPrivate.put(
      `/api/applicants/update-approval-status/${inputApplicantId}/${inputStatus}`,
      {},
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      },
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}