import { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// ASSETS
import LogoCompany from 'assets/images/logos/product-nle-connect-white.svg'

// COMPONENTS
import DialogConfirmation from 'components/DialogConfirmation/DialogConfirmation'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'
import { PrivateLayoutContext } from 'contexts/PrivateLayoutContext'

// CUSTOM COMPONENTS
import CustomDrawer, { DrawerHeader } from 'components/Customs/CustomDrawer'

// DATA
import { drawerNavigationList } from './drawerNavigationList'

// MUIS
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconSignOut from '@mui/icons-material/Logout'
import IconMenuOpen from '@mui/icons-material/MenuOpen'

// STYLES
import useStyles from './drawerUseStyles'

// UTILITIES
import { signOutUser } from 'utilities/authentication'

const Drawer = () => {
  const classes = useStyles()

  const location = useLocation()
  const navigate = useNavigate()

  const { auth, setAuth } = useContext(AllPagesContext)
  const [ dialogConfirmationObject, setDialogConfirmationObject ] = useState({})
  const { isDrawerExpanded, setIsDrawerExpanded } = useContext(PrivateLayoutContext)
  
  const isNavigationActive = (inputPath) => {
    if (location.pathname === inputPath) return true
    else return false
  }

  return (
    <>
      <CustomDrawer
        variant='permanent'
        open={isDrawerExpanded}
        className='zoom'
      >
        {/* HEADER */}
        <DrawerHeader>
          {/* TOGGEL DRAWER ICON */}
          <IconButton
            className={classes.headerIconToggle}
            onClick={() => setIsDrawerExpanded(current => !current)}
          >
            <IconMenuOpen/>
          </IconButton>

          {/* COMPANY LOGO */}
          {isDrawerExpanded &&
          <Box
            component='img'
            src={LogoCompany}
            alt=''
            className={classes.headerLogoCompany}
          />}
        </DrawerHeader>

        {/* NAVIGATION LIST */}
        <List disablePadding>
          {drawerNavigationList.map((item, index) => (
            <ListItem key={index}>
              <ListItemButton
                href={item.path}
                className={isNavigationActive(item.path)
                  ? `${classes.navigationItemButtonActive} ${classes.navigationItemButton}`
                  : classes.navigationItemButton
                }
              >
                {/* ICON */}
                <ListItemIcon className={isNavigationActive(item.path)
                  ? `${classes.navigationItemIconActive} ${classes.navigationItemIcon}`
                  : classes.navigationItemIcon
                }>
                  <item.icon/>
                </ListItemIcon>

                {/* TEXT */}
                <ListItemText
                  primary={item.title}
                  className={classes.navigationItemText}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        {/* USER BADGE */}
        <ListItem className={classes.logoutItem}>
          <ListItemButton
            onClick={() => navigate('/profile')}
            className={`${classes.navigationItemButton} ${classes.logoutButton}`}
            sx={(theme) => ({border : isDrawerExpanded ? `1px solid ${theme.palette.primary.light}` : 'none'})}
          >
            {/* AVATAR */}
            <ListItemIcon className={classes.navigationItemIcon}>
              <Avatar
                className={classes.logoutAvatar}
                sx={{ marginLeft: !isDrawerExpanded ? '-4px' : 'inherit' }}
              >
                {auth.fullName && auth.fullName[0].toUpperCase()}
              </Avatar>
            </ListItemIcon>

            {/* TEXT */}
            <ListItemText
              primary={
                <Typography
                  variant='subtitle2'
                  noWrap
                  className={classes.logoutItemTextPrimary}
                >
                  {auth.fullName ?? 'null'}
                </Typography>
              }
              secondary='Admin'
              className={classes.logoutItemText}
            />

            {/* LOG OUT */}
            <IconSignOut
              className={classes.logoutItemIconExtra}
              onClick={(event) => {
                event.stopPropagation()
                setDialogConfirmationObject({signout: 'Sign Out'})
              }
              }
            />
          </ListItemButton>
        </ListItem>
      </CustomDrawer>

      {/* SIGN OUT DIALOG CONFIRMATION */}
      <DialogConfirmation
        title='Are you sure you want to sign out?'
        caption=''
        dialogConfirmationObject={dialogConfirmationObject}
        setDialogConfirmationObject={setDialogConfirmationObject}
        cancelButtonText='Cancel'
        continueButtonText='Sign Out'
        onContinueButtonClick={() => signOutUser(setAuth)}
        onCancelButtonClick={() => setDialogConfirmationObject({})}
      />
    </>
  )
}

export default Drawer