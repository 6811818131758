//APIS
import axios, { axiosPrivate } from 'apis/axios'

export const postAuthenticateUser = async (inputBodyParams) => {
  try {
    const response = await axios.post('/api/admins/authenticate', inputBodyParams)
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const editUserProfile = async (
  inputSignal,
  inputBodyParams,
  inputToken
) => {
  try {
    const response = await axiosPrivate.put(
      '/api/admins/update', inputBodyParams,
      {
        signal: inputSignal,
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const getUserProfile = async (inputSignal, inputToken) => {
  try {
    const response = await axiosPrivate.get(
      '/api/admins/profile',
      { 
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const changePassword = async (
  inputSignal,
  inputBodyParams,
  inputToken
) => {
  try {
    const response = await axiosPrivate.put(
      '/api/admins/change-password', inputBodyParams,
      {
        signal: inputSignal, 
        headers: {'Authorization': `Bearer ${inputToken}`},
      }
    )
    return response
  } 
  catch (error) {
    if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}