// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mainContent: {
    padding: '20px',
    flexDirection: 'row',
    height: '652px',
    maxHeight: '100%',
  },
  drawer: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
}))

export default useStyles