export const getPieChartOptions = (
  inputLabel,
  inputColor,
) => {
  return {
    labels: inputLabel,
    legend : {
      show : false,
      position: 'bottom',
      horizontalAlign: 'left',
      fontSize: '12px'
    },
    plotOptions: {
      pie : {
        customScale: 1,
        expandOnClick: true,
        dataLabels: {
          offset: -20,
        },
      }
    },
    fill: {
      colors: inputColor,
      opacity: 1,
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'end',
      style: {
        fontSize: '12px',
        fontFamily: 'Poppins,sans-serif',
        fontWeight: 300,
      },
    },
    stroke: {
      show: false
    }
  }
}