import { useState, useContext } from 'react'

// ASSETS
import LogoProductNleConnet from 'assets/images/logos/product-nle-connect.svg'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import Input from '@mui/material/Input'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconVisibility from '@mui/icons-material/Visibility'
import IconVisibilityOff from '@mui/icons-material/VisibilityOff'

// MUI LABS
import LoadingButton from '@mui/lab/LoadingButton'

// SERVICES
import { postAuthenticateUser } from 'services/admins'

// STYLES
import useStyles from './signInUseStyles'

// UTILITIES
import { getDefaultErrorMessage } from 'utilities/string'
import { isEmailFormatValid } from 'utilities/validation'
import { setUserProfileToLocalStorage } from 'utilities/localStorage'

const SignIn = () => {
  const classes = useStyles()

  const { 
    setAuth,
    setSnackbarObject, 
  } = useContext(AllPagesContext)

  const initialSignUpForm = {
    email: '',
    password: '',
  }

  const initialErrorForm = {
    email: null,
    password: null,
  }

  const [ signInForm, setSignInform ] = useState(initialSignUpForm)
  const [ errorForm, setErrorForm ] = useState(initialErrorForm)
  const [ showPassword, setShowPassword ] = useState(false)

  const handleSignInFormChange = (event) => {
    setSignInform(current => ({
      ...current,
      [event.target.name]: event.target.value,
    }))
  }

  const updateErrorForm = (inputName, inputNewValue) => {
    setErrorForm(current => ({
      ...current,
      [inputName]: inputNewValue,
    }))
  }

  const handleFormButtonClick = async (inputEvent) => {
    inputEvent.preventDefault()
    
    if (!isEmailFormatValid(signInForm.email)) updateErrorForm('email', 'Email can only consist of letters, numbers, and special characters (@.-_).')
    else updateErrorForm('email', null)

    if (isEmailFormatValid(signInForm.email)) {
      const resultSignInUser = await postAuthenticateUser({
        email: signInForm.email,
        password: signInForm.password,
      })

      // SHOW SUCCESS MESSAGE AFTER SUCCESSFULLY SIGNING THE USER
      if(resultSignInUser.status === 200){
        setSnackbarObject({
          open: true,
          severity:'success',
          title:'',
          message:'Successfully sign in'
        })

        const userObject = {
          email: signInForm.email,
          accessToken: resultSignInUser.data.access_token,
        }

        setUserProfileToLocalStorage(userObject)
        setAuth(userObject)
      }
      // SHOW ERROR MESSAGE AFTER FAILED TO SIGNING THE USER
      else{
        setSnackbarObject({
          open: true,
          severity: 'error',
          title:'Failed to sign in',
          message: getDefaultErrorMessage(resultSignInUser),
        })
      }
    }
  }

  return (
    <form 
      className={classes.root}
      onSubmit={handleFormButtonClick}>
      {/* PRODUCT LOGO */}
      <Box
        component='img'
        src={LogoProductNleConnet}
        alt=''
        className={classes.productLogo}
      />

      {/* TITLE */}
      <Typography 
        variant='h6'
        className={classes.pageTitle}
      >
        Sign In
      </Typography>

      {/* EMAIL FORM */}
      <FormControl 
        variant='standard'
        className={classes.formInput}
        color='secondary'
        error={Boolean(errorForm.email)}
      >
        {/* EMAIL INPUT LABEL */}
        <InputLabel>
          Email
        </InputLabel>

        {/* EMAIL INPUT */}
        <Input 
          type='email' 
          name='email' 
          value={signInForm.email} 
          onChange={handleSignInFormChange}
        />

        {/* EMAIL HELPER TEXT */}
        {Boolean(errorForm.email) && 
        <FormHelperText>
          {errorForm.email}
        </FormHelperText>}
      </FormControl>

      {/* PASSWORD FORM */}
      <FormControl 
        variant='standard'
        className={classes.formInput}
        color='secondary'
        error={Boolean(errorForm.password)}
      >
        {/* PASSWORD INPUT LABEL */}
        <InputLabel>
          Password
        </InputLabel>

        {/* PASSWORD INPUT */}
        <Input 
          type={showPassword ? 'text' : 'password'}
          name='password'
          value={signInForm.password}
          onChange={handleSignInFormChange}
          endAdornment={
            <InputAdornment position='end'>
              <IconButton
                onClick={() => setShowPassword(current => !current)}
                edge='end'
              >
                {showPassword ? <IconVisibilityOff/> : <IconVisibility/>}
              </IconButton>
            </InputAdornment>
          }
        />

        {/* PASSWORD HELPER TEXT */}
        {Boolean(errorForm.password) &&     
        <FormHelperText >
          {errorForm.password}
        </FormHelperText>}
      </FormControl>

      {/* SIGN IN BUTTON */}
      <LoadingButton
        className={classes.formButton}
        variant='contained'
        type='submit'
      >
        Sign In
      </LoadingButton>
    </form>
  )
}

export default SignIn