import { useState, useEffect, useContext } from 'react'

// CHART DATA
import { getChartOptionsTM, getChartOptionsMBD } from './Chart/chartData'

// COMPONENTS
import TotalMoves from './TotalMoves'
import Chart from './Chart/Chart'
import MovesByDepot from './MovesByDepot'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MOMENT
import moment from 'moment'

// MUIS
import { Button } from '@mui/material'
import { Stack } from '@mui/material'
import { useTheme } from '@mui/material/styles'

// MUI ICONS
import FileDownloadIcon from '@mui/icons-material/FileDownload'

// SERVICE
import { getTotalMoves, downloadOverview } from 'services/home'

// STYLES
import useStyles from '../homeUseStyles'

const LeftContent = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const { setSnackbarObject, auth, setIsLoading } = useContext(AllPagesContext)
  const {selectedLocation} = props

  // LIST MBD CHART COLOR
  const chartColorsMBD = [
    theme.palette.success.main, 
    theme.palette.info.main, 
    theme.palette.warning.main, 
    '#FFD600',
    '#1DE9B6'
  ]

  // TOTAL MOVE STATES (TM)
  const [filteredDayTM, setFilteredDayTM] = useState(30)
  const [xaxisChartTM, setXaxisChartTM] = useState([])
  const [chartDataTM, setChartDataTM] = useState([])
  const [totalMovesTM, setTotalMovesTM] = useState(0)
  const [toolTipData, setToolTipData] = useState([])
  
  // MOVE BY DEPOT STATES (MBD)
  const [filteredDayMBD, setFilteredDayMBD] = useState(30)
  const [xaxisChartMBD, setXaxisChartMBD] = useState([])
  const [movesByDepot, setmovesByDepot] = useState([])

  // HANDLE DOWNLOAD OVERVIEW
  const handleDownloadOverview = async () => {
    setIsLoading(true)
    const abortController =  new AbortController()
    let queryParams = {
      duration: filteredDayTM,
      location: selectedLocation
    }

    // DOWNLOAD OVERVIEW
    const resultOverview = await downloadOverview(abortController.signal, auth.accessToken, queryParams)
    if(resultOverview.status === 200) {
      const url = window.URL.createObjectURL(new Blob([resultOverview.data]))
      let alink = document.createElement('a')
      alink.href = url
      alink.download = `total moves (${queryParams.location}, last ${queryParams.duration} days).xls`
      alink.click()
  
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Successfully download the file',
      })
      setIsLoading(false)
    } else {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: 'Failed to download file',
      })
      setIsLoading(false)
    }
  }

  // LOAD TOTAL MOVES
  const loadTotalMoves = async (inputIsMounted, inputAbortController) => {
    setIsLoading(true)
    let queryParams = {
      duration: filteredDayTM,
      location: selectedLocation
    }
    const resultTopLocation = await getTotalMoves(inputAbortController.signal, auth.accessToken, queryParams)

    let totalMoves = 0
    const calculateTotalMoves = resultTopLocation.data.forEach((item) => totalMoves += item.total)

    if(resultTopLocation.status === 200 && inputIsMounted) {
      const tempData = {
        name: 'Total Moves',
        data: resultTopLocation?.data?.reverse().map((item) => item.total)
      }
      setIsLoading(false)
      setChartDataTM([tempData])
      setTotalMovesTM(totalMoves)
      setToolTipData(resultTopLocation.data)
    } else setIsLoading(false)
  }

  // LOAD MOVES BY DEPOT
  const loadMovesByDepot = async (inputIsMounted, inputAbortController) => {
    let queryParams = {
      duration: filteredDayMBD,
      location: selectedLocation
    }
    const resultMovesByDepot = await getTotalMoves(inputAbortController.signal, auth.accessToken, queryParams)

    if(resultMovesByDepot.status === 200 && inputIsMounted) {
      let reverseData = resultMovesByDepot.data.reverse()

      // CREATE LIST DEPO
      let tempDepo = []

      reverseData.forEach((item) => item.fleet_count.forEach((newItem) => tempDepo.push(newItem?.fleetManager)))
      const removeDupArray = [...new Set(tempDepo)].map((item) => {
        return {
          name: item !== ''? item : 'No Label',
          data: []
        }
      })

      // GENERATE DEPO LIST DATA
      const generateData = removeDupArray.map((item) => {
        reverseData.forEach((newItem) => {
          const equivalentItem = newItem.fleet_count.find((tes) => tes.fleetManager === item.name)
          if(equivalentItem) item.data.push(equivalentItem.count)
          else item.data.push(0)
        })
        
        return item
      })

      // FUNCTION CALCULATE TOTAL ITEM
      const calculateItem = (inputItem) => {
        let total = 0
        inputItem.forEach((item) => (total += item))
        return total
      }
    
      // CREATE TOTAL MOVES FIELD
      const calculateTotalMoves = generateData.map((item) => {
        return {
          ...item,
          totalMoves: calculateItem(item.data)
        }
      })

      // DESCENDING SORT & SLICE(INDEX 0 - 4)
      const sortData = calculateTotalMoves.sort((a, b) => b.totalMoves - a.totalMoves).slice(0,5)
      
      // SET FIELD COLOR & CREATE ISACTIVE FIELD
      const finalData = sortData.map((item, index) => {
        return {
          ...item,
          color: chartColorsMBD[index],
          isActived: index === 0 || index === 1 || index === 2 ? true : false
        }
      })

      // SET MOVES BY DEPOT
      setmovesByDepot(finalData)
    }

  }

  // HANDLE MBD LIST FILTER 
  const handleMBDListItemClick = (inputItem) => {
    
    // VALIDATE IS A ACTIVED LIST
    const activedList = [...movesByDepot].find((item) => item.isActived && item.name === inputItem.name)

    // CALCULATE TOTAL ACTIVED LIST
    let countActiveList = 0
    const calculateAactiveList = [...movesByDepot].forEach((item) => {
      if(item.isActived === true) countActiveList++
    })

    // HANDLE FILTER
    if(activedList && countActiveList < 4) {
      const newMBDList = [...movesByDepot].map((item) => {
        if(item.name === inputItem.name) item.isActived = !item.isActived
        return item
      })
      setmovesByDepot(newMBDList)
    } else if(countActiveList < 3) {
      const newMBDList = [...movesByDepot].map((item) => {
        if(item.name === inputItem.name) item.isActived = !item.isActived
        return item
      })
      setmovesByDepot(newMBDList)
    } 
    // HANDLE WHEN ACTIVED LIST >= 3 && !activedList
    else setSnackbarObject({
      open: true,
      severity:'success',
      title:'',
      message:'Maximum choice of 3 data'
    })
  }

  // CREATE XAXIS CHART MS
  const createXaxisChartMS = () => {
    const today = moment(new Date()).add(1, 'day').format('YYYY-MM-DD')
    let xaxisCategories = []
  
    for (let i = 1; i <= filteredDayTM; i++) {
      xaxisCategories.push(moment(today).subtract(i, 'day').format('DD'))
    }
    setXaxisChartTM(xaxisCategories.reverse())
  }

  // CREATE XAXIS CHART MBD
  const createXaxisChartMBD = () => {
    const today = moment(new Date()).add(1, 'day').format('YYYY-MM-DD')
    let xaxisCategories = []
    
    for (let i = 1; i <= filteredDayMBD; i++) {
      xaxisCategories.push(moment(today).subtract(i, 'day').format('DD'))
    }
    setXaxisChartMBD(xaxisCategories.reverse())
  }

  // SIDE EFFECT MOVES BY DEPOT
  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()
    
    loadMovesByDepot(isMounted, abortController)
    createXaxisChartMBD()
    
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [filteredDayMBD, selectedLocation])


  // SIDE EFFECT TOTAL MOVES
  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    loadTotalMoves(isMounted, abortController)
    createXaxisChartMS()
    
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [filteredDayTM, selectedLocation])

  return (
    <Stack  className={classes.leftContentRoot}>
      {/* DOWNLOAD */}
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='flex-end'
        marginTop='5px'
        marginBottom='10px'
      >
        <Button
          variant='outlined'
          onClick={() => handleDownloadOverview()}
        >
          <Stack marginRight='9px'>
            <FileDownloadIcon/>
          </Stack>
          Download
        </Button>
      </Stack>
      {/* TOTAL MOVES CHART */}
      <Stack
        padding='20px 16px'
        border='solid 3px #0000001F'
        borderRadius='4px'
      >
        {/* TOTAL MOVES */}
        <TotalMoves 
          setFilteredDay={setFilteredDayTM}
          totalMoves={totalMovesTM}
        />

        {/* CHART */}
        <Stack marginTop='50px'>
          <Chart
            options={getChartOptionsTM(
              theme,
              '#4DBFAA',
              filteredDayTM,
              xaxisChartTM,
              toolTipData,
            )}
            series={chartDataTM}
          />
        </Stack>
      </Stack>

      {/* MOVES BY DEPOT CHART */}
      <Stack
        padding='20px 16px'
        border='solid 1px #0000001F'
        borderRadius='4px'
        marginTop='20px'
      >
        {/* TITLE AND FILTER */}
        <MovesByDepot 
          setFilteredDay={setFilteredDayMBD}
          listData={movesByDepot}
          handleMBDListItemClick={handleMBDListItemClick}
        />

        {/* CHART */}
        <Stack marginTop='50px'>
          <Chart
            options={getChartOptionsMBD(
              theme,
              movesByDepot.map(item => item.isActived && item.color),
              filteredDayMBD,
              xaxisChartMBD,
            )}
            series={movesByDepot.filter((item) => item.isActived)}
          />
        </Stack>
      </Stack>
    </Stack>
  )
}

export default LeftContent