// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYELS
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 70,
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: '0px 20px 0px 24px',
  },
  groupByText: {
    marginRight: 12,
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  gruopByInput: {
    width: 200,
    marginRight: 'auto',
    '&::before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  groupByIcon: {
    color: theme.palette.action.active,
  },
  groupByMenu: {
    '& .MuiPaper-root': {
      width: 200,
    },
  },
  dateRangeInput: {
    width: 300,
    marginRight: 4,
    '&::before': {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  dateRangePickerIcon: {
    color: theme.palette.action.active,
    cursor: 'pointer',
  },
  iconButton: {
    marginLeft: 8,
    borderRadius: 8,
    backgroundColor: theme.palette.primary.outlinedHoverBackground,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
  },
  columnsMenuRoot: {
    '& .MuiList-root': {
      padding: '12px 4px',
      width: 250,
    },
  },
  columnsMenuTitle: {
    fontWeight: 600,
    padding: '0px 18px 6px',
  },
  columnsMenuItem: {
    display: 'flex',
    padding: '0px 16px 0px 6px',
  },
  columnsMenuText: {
    color: theme.palette.text.secondary,
    fontWeight: 400,
  },
  totalRow : {
    marginRight: 8,
  }
}))

export default useStyles